.container {
    overflow: hidden;
    background: var(--secondary);
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.grid {
    flex:1;
}

.headerStyles {
    width: 100%;
    padding: 8px 16px;
    width: calc(100% - 32px);
    height: 51px;
    background: var(--secondary);
}

.mainGrid {
    height: calc(100vh - 90px);
}

.firstGrid {
        @media (--desktop) {
            width:100%;
            background:var(--blue);
            font-size: 26px;
        }

}

.icons{
    display:flex;
    justify-content:space-evenly !important;
    @media (--desktop) {
        margin-top: 40px !important;
        justify-content:space-around !important;
    }
    img {
    }
}

.secondGrid {
    background: #FFF8F0;
    display:flex;
    padding: 35px 16px 20px 16px;
    flex-direction: column;
    img {
        height: auto;
    }

    @media (--desktop) {
        padding: 0 125px;
        justify-content:center;
    }
}

.orange{
    color: #F5603F;
    margin-left: 8px;
}

.content{
    font-style: normal;
    font-weight: 600;
    font-size: var(--h1-font-size);
    color: var(--secondary);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (--desktop) {
        font-size: 26px;
        width:70%;
        height:100%;
        margin :0 auto;
        align-items:center
    }
}

.image{
    width: 55%;
    margin:auto;
}

.mobileText{
    font-weight: 600;
    font-size: var(--h1-font-size);
    top:30px;
    padding: 0 12px;
    margin-bottom: 30px;
    text-align: center;
}

.mobileImage{
    bottom: 0px;
}

.mob {
    position: fixed;
    bottom: 20px;
    width: calc(100% - 32px);
}

.spacer{
    margin: 0 14px;
}
