.SendMessage_field__kdL5k input {
    color: var(--black);
    padding: 16px;
    font-size: var( --d-font-size);
    background: #FFFFFF;
    box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.1);
    border-radius: 100px;
    margin-right: 8px;
    font-weight: 400;
}

.SendMessage_field__kdL5k fieldset {
    border: 0;
}

.SendMessage_container__9Qvz5 {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    display: flex;
    flex-direction: row;
    padding: var(--xs-spacing) var(--s-spacing);
    align-items: center;
    justify-content: center;
}

.SendMessage_button___0b6O {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    min-width: 36px;
    max-width: 36px;
    height: 36px;
    max-height: 36px;
    margin-left: var(--s-spacing);
    cursor: pointer;
    background: var(--primary);
    padding: 8px;
}

.ChatRoomDrawer_container__hiABN {
    padding: 16px;
    overflow: hidden;
    background: var(--secondary);
    width: calc(100vw - 32px);
    height: calc(100vh - 32px)
}

@media screen and (min-width: 420px) {

.ChatRoomDrawer_container__hiABN {
        width: calc(100vw - 32px) !important
}
    }

.ChatRoomDrawer_body__iKkzC {
    height: 100%;
}

/* .dateBlock {
    display: flex;
    flex-direction: column;
    padding: 0 var(--s-spacing);
} */
/*
.dateHeading {
    text-align: center;
    color: rgba(33, 33, 33, 0.5);
    font-size: var(--s-font-size);
} */

.ChatRoomDrawer_chatRoom__WHPTC {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 204px);
    overflow: auto;
}

.ChatRoomDrawer_otherUser__v_Lny img {
    border-radius: 50%;
}

.ChatRoomDrawer_otherUser__v_Lny {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ChatRoomDrawer_otherUser__v_Lny .ChatRoomDrawer_message__NDesG, .ChatRoomDrawer_you__Bx7pk .ChatRoomDrawer_message__NDesG {
    padding: 12px 16px;
    font-size: var(--d-font-size);
    max-width: 60vw;
    color: var(--black);
    margin-bottom: 4px;
    background: var(--black110);
    border-radius: 40px;
    position: relative;
}

.ChatRoomDrawer_otherUser__v_Lny .ChatRoomDrawer_message__NDesG {
    margin-right: 0;
    border-bottom-left-radius: 0;
}

.ChatRoomDrawer_you__Bx7pk {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.ChatRoomDrawer_youSec__NJXMN {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.ChatRoomDrawer_nameImgSec__Djrfk {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.ChatRoomDrawer_you__Bx7pk .ChatRoomDrawer_message__NDesG {
    background: var(--black110);
    border-bottom-right-radius: 0;
}

.ChatRoomDrawer_chat__WHgE6 {
    margin: 10px 0;
}

.ChatRoomDrawer_at__8AVID {
    text-transform: capitalize;
    padding: var(--xxs-spacing);
    padding-right: 0;
    width: 100%;

    font-size: var(--m-font-size);
    text-align: center;
    color: var(--black60);
}

.ChatRoomDrawer_shop__sb0jF {
    background-color: var(--secondary) !important;
    color: var(--black) !important;
    height: 60px;
    width:  60px;
    margin-right: 12px;
}

.ChatRoomDrawer_shopSection__htTu3 {
    display: flex;
    padding: 16px 0;
    color: var(--black);
    font-size: var(--h5-font-size);
    align-items: center;
}

.ChatRoomDrawer_otherUser__v_Lny .ChatRoomDrawer_at__8AVID {
    text-align: right;
}

.ChatRoomDrawer_loadMoreContainer___kfvX {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: var(--m-spacing);
    padding-top: 0;
}

.ChatRoomDrawer_loadMore__z9KUx {
    display: inline-block;
}

.ChatRoomDrawer_btn__AND48 {
    background-color: var(--secondary) !important;
    color: var(--primary) !important;
    text-transform: capitalize !important;
}

.ChatRoomDrawer_noMsg__Ifi8u {
    text-align: center;
    font-weight: 500;
    font-size: var(--h5-font-size);
    color: var(--black70);
    padding-top: 30px;
    padding-bottom: 12px;
}

.ChatRoomDrawer_noMsg1__Ro4VS {
    text-align: center;
    font-weight: 500;
    font-size: var(--d-font-size);
    color: var(--black50);
}

.ChatRoomDrawer_emptySec__4eRpH {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 150px;
}

.CustomShopChat_chatButton__V_WJi {
    margin-left: 12px !important
}

@media screen and (min-width: 420px) {

.CustomShopChat_chatButton__V_WJi {
        margin-left: var(--xxl-spacing) !important
}
    }

.CustomShopChat_chatButton__V_WJi img {
}

.CustomShopChat_unread__9JkIP {
    position: relative;
    top: -20px;
    left: -16px;
    display: block;
    border-radius: 50%;
    text-align: center;
    line-height: 2.5;
    font-size: var(--xs-font-size);
    border: 1px solid var(--white);
    width: 16px;
    height: 16px;
    background: var(--grey);
}

.CustomShopChat_chatButtonPosition__7aruw {
    position: fixed;
    right: 60px;
    bottom: 60px;
    z-index: 9999999
}

@media screen and (min-width: 420px) {

.CustomShopChat_chatButtonPosition__7aruw {
            position: fixed;
            right: 60px;
            bottom: 60px;
            z-index: 9999999
}
        }

.FollowUsAndMore_head__xGUBP {
    font-size: var(--h5-font-size);
    font-weight: var(--medium-bold);
    padding-top: 30px;
    padding-bottom: 20px;
    color: var(--black1);
    text-align: center;
}

.FollowUsAndMore_minimal__fUq9_ {
    margin-top: var(--l-spacing);
}

.FollowUsAndMore_minimal__fUq9_ .FollowUsAndMore_head__xGUBP {
        display: none;
    }

.FollowUsAndMore_minimal__fUq9_ .FollowUsAndMore_follow__YHB14 {
        justify-content: flex-start;
    }

.FollowUsAndMore_minimal__fUq9_ .FollowUsAndMore_follow__YHB14 img {
            width: 28px;
            height: 28px;
        }

.FollowUsAndMore_menu__vBcDK {

    display: flex;
    align-items: center;
    justify-content: center;
}

.FollowUsAndMore_menu__vBcDK .FollowUsAndMore_head__xGUBP {
        display: none;
    }

.FollowUsAndMore_menu__vBcDK .FollowUsAndMore_follow__YHB14 {
        justify-content: flex-start;

        margin-top: var(--xl-spacing);
        margin-bottom: 0;
    }

.FollowUsAndMore_menu__vBcDK .FollowUsAndMore_follow__YHB14 .FollowUsAndMore_card__s0tIy img {
                width: 22px;
                height: 22px;
            }

.FollowUsAndMore_menu__vBcDK .FollowUsAndMore_follow__YHB14 .FollowUsAndMore_card__s0tIy {

            padding-left: 0 !important;
            padding-right: var(--l-spacing) !important;
}

.FollowUsAndMore_menu__vBcDK .FollowUsAndMore_follow__YHB14 .FollowUsAndMore_card__s0tIy:last-child {
                padding-right: 0 !important;
            }

.FollowUsAndMore_head1__hpnuM {
    font-size: var(--d-font-size);
    font-weight: var(--medium-bold);
    padding-top: 30px;
    color: var(--black50);
    text-align: center;
}

.FollowUsAndMore_follow__YHB14 {
    display: flex;
    justify-content: center;
}

.FollowUsAndMore_start__J6ZzI{
    display: flex;
    justify-content: flex-start !important;
    margin-bottom: var(--xl-spacing);
}

.FollowUsAndMore_card__s0tIy {
    padding: 0 10px !important;
}


.FollowUsAndMore_card1__X5v1t {
    padding-right: 20px;
    padding-top: 20px;
}

.FollowUsAndMore_partners___jRb8 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.FollowUsAndMore_termsAndPolicy__LMVJk {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: var(--l-spacing);
}

.FollowUsAndMore_seperator__xKB9A {
    padding: 0 var(--m-spacing);
}

.FollowUsAndMore_link__W5TXa {
    color: var(--black60) !important;
    text-decoration: underline;
}

.Menu_paper__kcFtc {
    width: 70vw
}

@media screen and (min-width: 420px) {

.Menu_paper__kcFtc {
        width: 35vw !important
}
    }

.Menu_paperRight__nCOG7 {
    border-left: 1px solid #3c3c3c;
    border-top: 1px solid #3c3c3c;
}

.Menu_top__YM1l1 {
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding:var(--m-spacing) var(--l-spacing);
    padding-bottom: var(--l-spacing);
}

.Menu_section__LMu_A {
    display: flex;
    align-items: center;
    flex-direction: column;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 16px;
    margin: 16px;
    position: relative;
}

.Menu_clickable__wHC99 {
    display: flex;
    font-size: var(--d-font-size) !important;
    color: var(--black70) !important;
    text-transform: capitalize !important;
    font-weight: var(--medium-bold) !important;
    margin-bottom: 24px !important;
    padding: 0 16px !important;
    align-items: center;
}

.Menu_middle__zcSml {
    display: flex !important;
    flex-direction: column !important;
    height: 50%;
}

.Menu_name__pbKbg {
    width: calc(100% - 45px);
    text-align: left;
    padding-left: 12px !important;
}

.Menu_info__OSgxG {
    font-size: var(--d-font-size) !important;
    margin: 0 8px !important;
    margin-bottom: 8px !important;
}

.Menu_end__8ybv5 {
    display: flex;
    flex-direction: column;
}

.Menu_img__jWaGO {
    width: 66px !important;
    height: 66px !important;
    -o-object-fit: fill !important;
       object-fit: fill !important;
    border-radius: 50%;
}

.Menu_divider1__IB8aD {
    position: relative !important;
    left: 16px !important;
    right: 16px !important;
    width: calc(100% - 32px) !important;
    color: var(--black110) !important;
    margin-bottom: 24px !important;
}

.Menu_desc__VuOMC {
    font-size: var(--h5-font-size);
    color: var(--black1);
    font-weight: var(--medium-bold);
    padding: 8px 0;
}

.Menu_email__X5fqW {
    font-size: var(--m-font-size);
    color: var(--black150);
}

.Menu_logout__7aXR8 {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Menu_edit__XeHfo {
    position: absolute;
    right: 16px;
    top: 16px;
}

.Menu_defaultUser__vPljn {
    background: var(--secondary);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
    height: 46px;
    width: 46px;
    padding: var(--l-spacing);
    border-radius: 50%;
    margin-bottom: var(--m-spacing);
    display: flex;
    align-items: center;
    justify-content: center;
}

.Menu_defaultIcon__eGBei {
    width: 24px;
    height: 22px;
}

.Menu_closeIcon__B__Yt{
    top:0px;
    margin-left: auto;
    right: 0px;
    margin-bottom: 12px;
}

.Menu_options__nN1PZ{
    border-bottom: 1px solid var(--black110);
    padding:16px 0;
}

.Menu_spacerh__M_5Qt{
    margin:0 4px;
}


.Menu_mainTitle__ijxbO {
    font-weight: var(--medium-bold);
    margin: var(--l-spacing) 0;
    font-size: var(--d-font-size);
    cursor: pointer;
    width: 100%;
    color: #212121 !important;
  }
  
  .Menu_mainTitle__ijxbO:focus {
    outline: none;
    color: var(--primary);
  }
  
  .Menu_subMenu__Q8HnK {
    font-size: var(--m-font-size) !important;
    display: none;
    margin: var(--l-spacing);
    font-weight: var(--medium-bold) !important; 
  }
  
  .Menu_link__6Dn_D {
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: var(--s-spacing);
    margin: var(--l-spacing) 0px;
    color: var(--black8);
    padding-left: 0px !important;
  }
  
  .Menu_link__6Dn_D:hover, .Menu_active__nnz5H {
    color: var(--primary);
  }
  
  .Menu_show__XK1_Z {
    display: block;
  }
  
  .Menu_flex__3bt5C {
    display: flex;
    align-items: center;
  }

  .Menu_flex__3bt5C:last-child {
    border-bottom: none !important;
}
  
  .Menu_icon__7B3ka{
    padding-right: var(--xl-spacing);
    width: 22px;
    height: 22px;
  }
  
  
  .Menu_chevron__0jbOv {
    float: right;
  }

  .Menu_subMenuText__X0K41 {
    font-size: var(--d-font-size) !important;
    font-weight: var(--medium-bold) !important;
    color: var(--black80);
      margin-left: var(--s-spacing);
  }


  .Menu_flex2__OtkCX {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }

  .Menu_footer__bYJsB{
    bottom: 40px;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .Menu_wlogo__kVw5y{
      margin :0 6px;
  }
  
  .Menu_tnc__zYwVu {
      font-size: 10px;
      color: var(--black70);
      text-align: center;
  }

  .Menu_icons__BvkgT{
      width: 22px;
      height: 22px;
  }

  .Menu_spacer__JtEhE {
      margin: 20px 0;
  }

  .Menu_menu__Mq5Aj {
      border-bottom: 1px solid var(--black110);
  }

  .Menu_emailMenu__HUlUf{
      margin-top: -6px !important;
  }

  .Menu_marginLeft__nQ24A{
      margin-left: var(--xxl-spacing);
      font-size: var(--s-font-size);
      color: var(--black60);
      margin-bottom: var(--xxl-spacing);
  }

.Menu_text2__TA_jV{
    font-weight: var(--medium-bold);
    color: var(--black60);
    text-align: center;
    line-height: 1.5;
}

.Menu_isDirect__tFUju {
    display: none;
}
.Header_banner__RV3jW {
    padding-left: var(--m-spacing) !important;
    z-index: 1001;
    cursor: pointer !important
}

@media screen and (min-width: 420px) {

.Header_banner__RV3jW {
        padding-left: var(--xxl-spacing) !important
}
    }

.Header_newIcon__dJzF9 {
    cursor: pointer !important;
    margin-left: 12px
}

@media screen and (min-width: 420px) {

.Header_newIcon__dJzF9 {
        margin-left: var(--xxl-spacing) !important
}
    }

.Header_addPost__whwwF {
    width: 45px;
    height: 45px;
    right: 0;
    border-left: 0;
    z-index: 1001;
    top: 0;
}

.Header_showSearch__uqdFw {
    width: 45px;
    height: 45px;
    right: 0;
    border-left: 0;
    z-index: 1001;
}

.Header_header__rhJYX {
    position: absolute;
    z-index: 1000;
    width: 100%;
    top: 0;
    background: var(--primary);
}

.Header_menu__OPYHI {
    position: -webkit-sticky;
    position: sticky;
    width: 50% !important;
    margin-left: auto;
    margin-right: 0;
    right: 0 !important;
    top: 32px !important;
    background: transparent !important;
    box-shadow: none !important;
}

.Header_left10__Q0Ba8 {
    margin-left: 8px !important;
    height: 30px;
}

.Header_right__zeKN0 {
    font-size: var(--h5-font-size);
    color: var(--black80);
    padding-left: 8px;
    font-weight: var(--semi-bold);
    flex: 1 1;
}

.Header_shop__43z7r {
    padding: 0;
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
    flex: 1 1;
}

.Header_flex__0CH3h {
    display: flex;
    align-items: center;
}

.Header_bigName__k8jOV {
}

.Header_count__HhfDQ {
    font-size: var(--xs-font-size);
    font-weight: var(--semi-bold);
    height: 14px;
    width: 14px;
    color: var(--secondary);
    border: 2px solid var(--secondary);
    background: var(--primary);
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Header_relative__ljtvw {
    position: relative;
}

.Header_emptyIcon__40aLR {
    cursor: pointer;
    height: auto !important;
    width: auto !important;
    border-radius: 0 !important;
    padding: var(--s-spacing);
}

.Header_shopIcon__XZByZ {
    cursor: pointer;
    height: 32px;
    width: 32px;
    border-radius: 50%;
}

.Header_default__b6AOg {
    background: var(--secondary-grey);
    height: 32px;
    width: 32px;
    border-radius: 50%;
}

.Header_landingPageLogo__j135i{
    width: 106px;
    height: 33px
}

@media screen and (min-width: 420px) {

.Header_landingPageLogo__j135i{
        width: 162px;
        height: 50px
}
    }

.landingPage_container__FCdPx {
    overflow: hidden;
    background: var(--secondary);
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.landingPage_grid__vlBTx {
    flex:1 1;
}

.landingPage_headerStyles__dOVvH {
    width: 100%;
    padding: 8px 16px;
    width: calc(100% - 32px);
    height: 51px;
    background: var(--secondary);
}

.landingPage_mainGrid__RdHzz {
    height: calc(100vh - 90px);
}

@media screen and (min-width: 420px) {

.landingPage_firstGrid__gf_Iz {
            width:100%;
            background:var(--blue);
            font-size: 26px

}
        }

.landingPage_icons__LMJcl{
    display:flex;
    justify-content:space-evenly !important
}

@media screen and (min-width: 420px) {

.landingPage_icons__LMJcl{
        margin-top: 40px !important;
        justify-content:space-around !important
}
    }

.landingPage_icons__LMJcl img {
    }

.landingPage_secondGrid__eYJrm {
    background: #FFF8F0;
    display:flex;
    padding: 35px 16px 20px 16px;
    flex-direction: column;
}

.landingPage_secondGrid__eYJrm img {
        height: auto;
    }

@media screen and (min-width: 420px) {

.landingPage_secondGrid__eYJrm {
        padding: 0 125px;
        justify-content:center
}
    }

.landingPage_orange__eCN6S{
    color: #F5603F;
    margin-left: 8px;
}

.landingPage_content__Zu_w1{
    font-style: normal;
    font-weight: 600;
    font-size: var(--h1-font-size);
    color: var(--secondary);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
}

@media screen and (min-width: 420px) {

.landingPage_content__Zu_w1{
        font-size: 26px;
        width:70%;
        height:100%;
        margin :0 auto;
        align-items:center
}
    }

.landingPage_image__MABS7{
    width: 55%;
    margin:auto;
}

.landingPage_mobileText__GNtPQ{
    font-weight: 600;
    font-size: var(--h1-font-size);
    top:30px;
    padding: 0 12px;
    margin-bottom: 30px;
    text-align: center;
}

.landingPage_mobileImage__T6Dwh{
    bottom: 0px;
}

.landingPage_mob__8wfu9 {
    position: fixed;
    bottom: 20px;
    width: calc(100% - 32px);
}

.landingPage_spacer__cZ6L5{
    margin: 0 14px;
}

